import React, { useEffect, useRef, useState } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AccessibleIcon from "@material-ui/icons/Accessible";
import { withRouter } from "react-router-dom";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "../../../assets/css/minimal.css";
import GroupIcon from "@material-ui/icons/Group";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { FaPenFancy } from "react-icons/fa";
import { FaElementor } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MicIcon from "@material-ui/icons/Mic";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import MicNoneIcon from "@material-ui/icons/MicNone";
import ChatIcon from "@material-ui/icons/Chat";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import logo from "../../../assets/images/logo.png";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import PersonIcon from "@material-ui/icons/Person";
import socketIOClient from "socket.io-client";
import Badge from "@material-ui/core/Badge";
import { TextField } from "@material-ui/core";
// const SOCKET_SERVER_URL = "https://apidev.ppostit.com";
const SOCKET_SERVER_URL = "https://api.ppostit.com";

// const SOCKET_SERVER_URL = "http://67.207.70.240:4140";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "primary",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  iconscolor: {
    color: "#601124",
  },
  dwr: {
    "&.MuiListItem-button:hover": {
      backgroundColor: "white",
    },
  },
  mnu: {
    cursor: "pointer",
    color: "white",
  },
  menuclr: {
    backgroundColor: "#e9e7e752",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

function Appbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const messagesocketRef = useRef();
  const [islogout, setlogout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [menu_open, setMenuOpen] = React.useState(false);
  const [story_menu_open, setStoryMenuOpen] = React.useState(false);
  const [podcast_open, setPodcastOpen] = React.useState(false);
  const [menu, setmenu] = React.useState("Dashboard");
  const [dashboard, setDashboard] = React.useState("Dashboard");
  const [users, setusers] = React.useState("Users");
  const [secrtery, setSecrtery] = React.useState("secrtery");
  const [appointment, setAppointment] = React.useState("appointment");
  const [chat, setchat] = React.useState("chat");
  const [blogs, setblogs] = React.useState("All blogs");
  const [blog_category, setBlogCategory] = React.useState("blog_category");
  const [blog_authors, setBlogAuthors] = React.useState("blog_author");
  const [podcast, setPodcast] = React.useState("podcast");
  const [podcast_category, setPodcastCategory] =
    React.useState("podcast_category");
  const [photos, setPhotos] = React.useState("photos");
  const [videos, setVideos] = React.useState("video");
  const [story, setStory] = React.useState("story");
  const [expire_story, setExpireStory] = React.useState("expire_story");
  const [inactive_story, setInactiveStory] = React.useState("inactive_story");
  const [news, setNews] = React.useState("news");
  const [setting, setSetting] = React.useState("setting");
  const [patient, setPatient] = React.useState("patient");
  const [clinic, setClinic] = React.useState("clinic");
  const [admin_user, setAdminUser] = React.useState("admin");
  const [message_count, setMessageCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [userData, setUserData] = React.useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const handleClose = () => {
    setlogout(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleUpdatePassword = () => {
    props.history.push("/postit/updatepassword");
  };
  const logout = () => {
    setlogout(true);
  };
  const serverlogout = () => {
    const token = localStorage.getItem("token");
    let requestObj = {
      path: "/api/admin/logout",
      method: "GET",
      headers: {
        "x-sh-auth": token,
      },
    };
    // invokeApi(requestObj).then((res) => {
    //   console.log(res, "RESPONSE");
    // });
    return true;
  };
  const handleagreelogout = () => {
    setlogout(false);
    props.history.push("/login");
    if (serverlogout()) {
      localStorage.clear();
      props.history.push("/login");
    }
  };
  // increment count
  const message_incremnt_count = async (user_id) => {
    // call api
    console.log("INCREMENT COUNT");
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/add_message_count/${user_id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then(async (res) => {
      if (res.code == 200) {
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      props.history.push("/login");
    }
    if (localStorage.getItem("menu")) {
      const menu_item = localStorage.getItem("menu");
      localStorage.removeItem("menu");
      props.history.push("/postit/" + menu_item.replace(/\s/g, ""));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      messagesocketRef.current = socketIOClient(SOCKET_SERVER_URL, {
        query: { roomId: localStorage.getItem("admin_id") },
      });
    }
  }, []);
  const menu_item = localStorage.getItem("menu");

  useEffect(() => {
    messagesocketRef.current.on("receiver_mg_count", async (data) => {
      if (!window.location.href.includes("postit/chat")) {
        console.log("check true");
        setMessageCount((c) => c + 1);
        await message_incremnt_count(data.sender_id);
      }
    });
  }, []);

  const token = localStorage.getItem("token");

  if (!token) {
    return (window.location = "/login");
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
            <h2
              style={{ color: "white", fontWeight: "bold", marginTop: "15px" }}
            >
              Post it
            </h2>
            <div className="menu-account">
              <Menu
                menuButton={
                  <AccountCircleIcon
                    className={classes.mnu}
                  ></AccountCircleIcon>
                }
              >
                <MenuItem className={classes.menuclr}>Account</MenuItem>
                <MenuItem
                  className={classes.menuclr}
                  onClick={handleUpdatePassword}
                >
                  Change password
                </MenuItem>
                <MenuItem onClick={logout} className={classes.menuclr}>
                  Logout
                </MenuItem>
              </Menu>
              <Menu
                menuButton={
                  <ArrowDropDownIcon
                    className={classes.mnu}
                  ></ArrowDropDownIcon>
                }
              >
                <MenuItem>
                  <div>
                    <p style={{ marginTop: "0", marginBottom: "5px" }}>
                      <strong>Post it</strong>
                    </p>
                    <p style={{ marginTop: "0", marginBottom: "0" }}>
                      {userData?.email}
                    </p>
                  </div>
                </MenuItem>

                <MenuItem
                  style={{ bordertop: "2px solid #000" }}
                  className={classes.menuclr}
                  onClick={handleUpdatePassword}
                >
                  Change password
                </MenuItem>
                <MenuItem onClick={logout} className={classes.menuclr}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img src={logo} className={classes.lg} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              style={{
                width: "200px",
                border: "1px solid #d8d433",
                marginTop: "10px",
                borderRadius: "10px",
              }}
              type="text"
              placeholder="Search"
            />
          </div> */}

          <List>
            <ListItem
              style={
                dashboard === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"dashboard"}
              onClick={() => {
                setmenu("Dashboard");
                localStorage.setItem("menu", "Dashboard");

                setDashboard("Dashboard");
                props.history.push("/postit/dashboard");
              }}
            >
              <ListItemIcon
                style={
                  dashboard === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <DashboardIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary={dashboard} className={classes.txt} />
            </ListItem>

            <ListItem
              style={
                admin_user === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"admin"}
              onClick={() => {
                setmenu("admin");
                localStorage.setItem("menu", "admin");
                setAdminUser("admin");
                props.history.push("/postit/admin");
              }}
            >
              <ListItemIcon
                style={
                  admin_user === menu
                    ? { color: "white" }
                    : { color: "#d8d433" }
                }
              >
                <PersonIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Admin" className={classes.txt} />
            </ListItem>
            <ListItem
              style={
                users === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"users"}
              onClick={() => {
                setmenu("users");
                localStorage.setItem("menu", "users");

                setusers("users");
                props.history.push("/postit/users");
              }}
            >
              <ListItemIcon
                style={
                  users === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <GroupIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="User" className={classes.txt} />
            </ListItem>
            <ListItem
              style={
                patient === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"patient"}
              onClick={() => {
                setmenu("patient");
                localStorage.setItem("menu", "patient");
                setPatient("patient");
                props.history.push("/postit/patient");
              }}
            >
              <ListItemIcon
                style={
                  patient === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <AccessibleIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Patients" className={classes.txt} />
            </ListItem>
            <ListItem
              style={
                clinic === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"clinic"}
              onClick={() => {
                setmenu("clinic");
                localStorage.setItem("menu", "clinic");
                setClinic("clinic");
                props.history.push("/postit/clinic");
              }}
            >
              <ListItemIcon
                style={
                  clinic === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <LocalHospitalIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Clinic" className={classes.txt} />
            </ListItem>
            {/* <ListItem
              style={
                secrtery === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"Secrtery"}
              onClick={() => {
                setmenu("secrtery");
                localStorage.setItem("menu", "secrtery");
                setSecrtery("secrtery");
                props.history.push("/postit/secrtery");
              }}
            >
              <ListItemIcon
                style={
                  secrtery === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <AccountBoxIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Secretaries" className={classes.txt} />
            </ListItem> */}
            <ListItem
              style={
                appointment === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"Appointments"}
              onClick={() => {
                setmenu("appointment");
                localStorage.setItem("menu", "appointment");
                setAppointment("appointment");
                props.history.push("/postit/appointment");
              }}
            >
              <ListItemIcon
                style={
                  appointment === menu
                    ? { color: "white" }
                    : { color: "#d8d433" }
                }
              >
                <AccessTimeIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Appointment" className={classes.txt} />
            </ListItem>
            <ListItem
              style={
                chat === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={`Chat`}
              onClick={() => {
                setMessageCount(0);
                setmenu("chat");
                localStorage.setItem("menu", "chat");
                setchat("chat");
                props.history.push("/postit/chat");
              }}
            >
              <ListItemIcon
                style={
                  chat === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <ChatIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText
                primary={`Chat`}
                className={classes.txt}
              ></ListItemText>
              {message_count > 0 ? (
                <Badge
                  style={{ marginLeft: 0 }}
                  badgeContent={message_count}
                  color="primary"
                ></Badge>
              ) : null}
            </ListItem>

            <ListItem button onClick={() => setMenuOpen(!menu_open)}>
              <ListItemIcon style={{ color: "#d8d433" }}>
                <FaPenFancy style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Blogs" />
              {menu_open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={menu_open} timeout="auto" unmountOnExit>
              <ListItem
                style={
                  blogs === menu
                    ? { backgroundColor: "#d8d433", color: "white" }
                    : {}
                }
                className={classes.nested}
                button
                key={"blogs"}
                onClick={() => {
                  setmenu("All blogs");
                  localStorage.setItem("menu", "blogs");
                  setblogs("All blogs");
                  props.history.push("/postit/blogs");
                }}
              >
                <ListItemIcon
                  style={
                    blogs === menu ? { color: "white" } : { color: "#d8d433" }
                  }
                >
                  <FaPenFancy style={{ width: 22 }} />
                </ListItemIcon>
                <ListItemText primary="All Blogs" className={classes.txt} />
              </ListItem>
              <ListItem
                style={
                  blog_category === menu
                    ? { backgroundColor: "#d8d433", color: "white" }
                    : {}
                }
                className={classes.nested}
                button
                key={"blog_category"}
                onClick={() => {
                  setmenu("blog_category");
                  localStorage.setItem("menu", "blogCategories");
                  setBlogCategory("blog_category");
                  props.history.push("/postit/blogCategories");
                }}
              >
                <ListItemIcon
                  style={
                    blog_category === menu
                      ? { color: "white" }
                      : { color: "#d8d433" }
                  }
                >
                  <FaElementor style={{ width: 22 }} />
                </ListItemIcon>
                <ListItemText
                  primary="Blog categories"
                  className={classes.txt}
                />
              </ListItem>
              <ListItem
                style={
                  blog_authors === menu
                    ? { backgroundColor: "#d8d433", color: "white" }
                    : {}
                }
                className={classes.nested}
                button
                key={"blog_author"}
                onClick={() => {
                  setmenu("blog_author");
                  localStorage.setItem("menu", "blog-authors");
                  setBlogAuthors("blog_author");
                  props.history.push("/postit/blog-authors");
                }}
              >
                <ListItemIcon
                  style={
                    blog_authors === menu
                      ? { color: "white" }
                      : { color: "#d8d433" }
                  }
                >
                  <FaUserEdit style={{ width: 22 }} />
                </ListItemIcon>
                <ListItemText primary="Blog Authors" className={classes.txt} />
              </ListItem>
            </Collapse>
            <ListItem button onClick={() => setPodcastOpen(!podcast_open)}>
              <ListItemIcon style={{ color: "#d8d433" }}>
                <MicNoneIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Podcasts" />
              {podcast_open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={podcast_open} timeout="auto" unmountOnExit>
              <ListItem
                style={
                  podcast === menu
                    ? { backgroundColor: "#d8d433", color: "white" }
                    : {}
                }
                className={classes.nested}
                button
                key={"podcast"}
                onClick={() => {
                  setmenu("podcast");
                  localStorage.setItem("menu", "podcast");

                  setPodcast("podcast");
                  props.history.push("/postit/podcast");
                }}
              >
                <ListItemIcon
                  style={
                    podcast === menu ? { color: "white" } : { color: "#d8d433" }
                  }
                >
                  <MicIcon style={{ width: 22 }} />
                </ListItemIcon>
                <ListItemText primary="All Podcasts" className={classes.txt} />
              </ListItem>
              <ListItem
                style={
                  podcast_category === menu
                    ? { backgroundColor: "#d8d433", color: "white" }
                    : {}
                }
                className={classes.nested}
                button
                key={"podcast_catgeory"}
                onClick={() => {
                  setmenu("podcast_category");
                  localStorage.setItem("menu", "podcast-category");
                  setPodcastCategory("podcast_category");
                  props.history.push("/postit/podcast-category");
                }}
              >
                <ListItemIcon
                  style={
                    podcast_category === menu
                      ? { color: "white" }
                      : { color: "#d8d433" }
                  }
                >
                  <FaUserEdit style={{ width: 22 }} />
                </ListItemIcon>
                <ListItemText
                  primary="Podcast Categories"
                  className={classes.txt}
                />
              </ListItem>
            </Collapse>
            <ListItem
              key={Math.random()}
              style={
                photos === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              onClick={() => {
                setmenu("photos");
                localStorage.setItem("menu", "photos");

                setPhotos("photos");
                props.history.push("/postit/photos");
              }}
            >
              <ListItemIcon
                key={Math.random()}
                style={
                  photos === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <PhotoSizeSelectActualIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText
                key={Math.random()}
                primary="Photos"
                className={classes.txt}
              />
            </ListItem>
            <ListItem
              style={
                videos === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={"videos"}
              onClick={() => {
                setmenu("videos");
                localStorage.setItem("menu", "videos");
                setVideos("videos");
                props.history.push("/postit/videos");
              }}
            >
              <ListItemIcon
                style={
                  videos === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <VideoLibraryIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Videos" className={classes.txt} />
            </ListItem>
            <ListItem button onClick={() => setStoryMenuOpen(!story_menu_open)}>
              <ListItemIcon>
                <CropOriginalIcon style={{ color: "#d8d433" }} />
              </ListItemIcon>
              <ListItemText primary="Story" />
              {story_menu_open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={story_menu_open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  style={
                    story === menu
                      ? { backgroundColor: "#d8d433", color: "white" }
                      : {}
                  }
                  button
                  key={Math.random()}
                  onClick={() => {
                    setmenu("story");
                    localStorage.setItem("menu", "story");

                    setStory("story");
                    props.history.push("/postit/story");
                  }}
                >
                  <ListItemIcon
                    style={
                      story === menu ? { color: "white" } : { color: "#d8d433" }
                    }
                  >
                    <SettingsBackupRestoreIcon style={{ width: 22 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Available Stories"
                    className={classes.txt}
                  />
                </ListItem>

                <ListItem
                  style={
                    expire_story === menu
                      ? { backgroundColor: "#d8d433", color: "white" }
                      : {}
                  }
                  button
                  key={Math.random()}
                  onClick={() => {
                    setmenu("expire_story");
                    localStorage.setItem("menu", "expire_story");

                    setExpireStory("expire_story");
                    props.history.push("/postit/expire_story");
                  }}
                >
                  <ListItemIcon
                    style={
                      expire_story === menu
                        ? { color: "white" }
                        : { color: "#d8d433" }
                    }
                  >
                    <SettingsBackupRestoreIcon style={{ width: 22 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Expire Stories"
                    className={classes.txt}
                  />
                </ListItem>
                {/* Inactive stories............ */}
                {/* <ListItem
                  style={
                    inactive_story === menu
                      ? { backgroundColor: "#d8d433", color: "white" }
                      : {}
                  }
                  button
                  key={Math.random()}
                  onClick={() => {
                    setmenu("inactive_story");
                    localStorage.setItem("menu", "inactive_story");

                    setInactiveStory("inactive_story");
                    props.history.push("/postit/inactive_story");
                  }}
                >
                
                  <ListItemIcon
                    style={
                      inactive_story === menu
                        ? { color: "white" }
                        : { color: "#d8d433" }
                    }
                  >
                    <SettingsBackupRestoreIcon style={{ width: 22 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Inactive Stories"
                    className={classes.txt}
                  />
                </ListItem> */}
                {/* Inactive stories............ end */}
              </List>
            </Collapse>

            <ListItem
              style={
                news === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={Math.random()}
              onClick={() => {
                setmenu("news");
                localStorage.setItem("menu", "list-news");
                setNews("news");
                props.history.push("/postit/list-news");
              }}
            >
              <ListItemIcon
                style={
                  news === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <FaRegNewspaper style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="News" className={classes.txt} />
            </ListItem>
            <ListItem
              style={
                setting === menu
                  ? { backgroundColor: "#d8d433", color: "white" }
                  : {}
              }
              button
              key={Math.random()}
              onClick={() => {
                setmenu("setting");
                localStorage.setItem("menu", "setting");
                setSetting("setting");
                props.history.push("/postit/setting");
              }}
            >
              <ListItemIcon
                style={
                  setting === menu ? { color: "white" } : { color: "#d8d433" }
                }
              >
                <SettingsIcon style={{ width: 22 }} />
              </ListItemIcon>
              <ListItemText primary="Setting" className={classes.txt} />
            </ListItem>
          </List>

          <Divider />

          <List>
            {["Logout"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <PowerSettingsNewIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} onClick={logout} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Dialog
          open={islogout}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to logout?`}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleagreelogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          {props.children}
        </main>
      </div>
    </>
  );
}
export default withRouter(Appbar);
